/* JavaScript for the 'ICustomTheme' Plone browser layer */
(function($) {
    $(document).ready(function(){

        // animated text on home page
        if ($(".template-homepage-view .banner_text #text").length) {
            consoleText(['Terre Haute.'], 'text',['white']);
        }

        // sticky nav
        var navwrapper = $(".city_top_navigation");
        var navwrappertop = $(navwrapper).offset().top;
        var navwrapperheight = $(navwrapper).outerHeight();
        if($(navwrapper).length){
            $(window).scroll(function() {
              if ($(this).scrollTop() > navwrappertop){
                    animate: true,
                  $('.city_top_navigation').addClass("sticky");
                  $("body").css("margin-top", navwrapperheight);
              }
              else{
                  $("body").css("margin-top", 0);
                  $('.city_top_navigation').removeClass("sticky");
              }
            });
        }


        // align grid view box heights
        if ($(".template-grid_view").length) {
            var resizeTimer;
            setTimeout(function() {
                setHeightByRow(".city_service2_text", ".city_service2_desc");
            }, 2000);
            $(window).on('load', function(e) {
              setHeightByRow(".city_service2_text", ".city_service2_desc");
            });
            $(window).on('resize', function(e) {
              clearTimeout(resizeTimer);
              resizeTimer = setTimeout(function() {
                setHeightByRow(".city_service2_text", ".city_service2_desc");
              }, 250);
            });

        }


    });
})(jQuery);


// set items to same height
function setHeight(parentSelector, childSelector) {
  var items = $(parentSelector).find(childSelector);
  if (items.length<2) {
      return
  }
  var newHeight = 0;
  items.height('auto');
  items.each(function() {
    theHeight = $(this).height();
    if (theHeight > newHeight) {
      newHeight = theHeight;
    }
  });
  items.height(newHeight);
}

function setHeightByRow(parentSelector, childSelector) {
  var items = $(parentSelector).find(childSelector);
  if (items.length<2) {
      return
  }
  var newHeight = 0;
  var currentTop = $(items).parent().offset()['top'];
  var row = [];
  items.height('auto');
  items.each(function(index) {
    if ($(this).parent().offset()['top'] != currentTop) {
      currentTop = $(this).parent().offset()['top'];
      $(row).each(function(){
        $(this).height(newHeight);
      });
      newHeight = 0;
      row = []
    }
    row.push($(this));
    theHeight = $(this).height();
    if (theHeight > newHeight) {
      newHeight = theHeight;
    }
  });
  $(row).each(function(){
    $(this).height(newHeight);
  });
}
